import React, { useState } from "react";
import emailjs from "emailjs-com";
import contact from "../Assets/contact1.svg";
import phone from "../Assets/icons/phone.svg";
import message from "../Assets/icons/message.svg";

import "./Styles/Contact.css";

const Contact = () => {
  // info.psychologicalways@gmail.com
  const [formData, setFormData] = useState({});

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      from_name: formData.name,
      from_phone: formData.phone,
      from_email: formData.email,
      message: formData.message,
    };

    emailjs.send(
      "service_7jsw7so",
      "template_gefll9y",
      templateParams,
      "user_KOyGLolURuMHxZuEYxLPA"
    );

    setFormData({});
    alert("Form submitted, we'll get back to you soon!");
  };
  return (
    <div className="contact">
      <div className="contact__formContainer">
        <form onSubmit={handleSubmit} className="contact__form">
          <h1 className="heading">Get In Touch</h1>
          <div className="contact__details">
            <div className="contact__data">
              <img src={message} alt="" />
              <p>
                <a href="mailto:psychologicalways@gmail.com">
                  psychologicalways@gmail.com
                </a>
              </p>
            </div>

            <div className="contact__data">
              <img src={phone} alt="" />

              <p>
                <a href="tel:+917605025775">+91-76050-25775</a> /{" "}
                <a href="tel:+919163009839">+91-91630-09839</a>
              </p>
            </div>
          </div>
          <input
            value={formData.name || ""}
            onChange={handleChange}
            type="text"
            name="name"
            placeholder="Name"
            maxLength={32}
            required
          />
          <input
            value={formData.email || ""}
            onChange={handleChange}
            type="email"
            name="email"
            placeholder="Email"
            maxLength={50}
            required
          />
          <input
            value={formData.phone || ""}
            onChange={handleChange}
            type="phone"
            name="phone"
            placeholder="Phone (optional)"
            maxLength={15}
          />
          <textarea
            value={formData.message || ""}
            onChange={handleChange}
            name="message"
            placeholder="Message"
            maxLength={99}
            required
          />
          <button className="contact__btn" type="submit">
            Submit
          </button>
        </form>
      </div>
      <div className="contact__image">
        <img src={contact} alt="" />
      </div>
    </div>
  );
};

export default Contact;
