import { useState } from "react";
import { CgMenuRight, CgClose } from "react-icons/cg";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import logo from "../Assets/logo.svg";
import "./Styles/NavBar.css";

const NavBar = () => {
  const [toggle, setToggle] = useState(false);
  const handleToggle = () => setToggle(!toggle);

  const scrollToTop = () => window.scrollTo(0, 0);

  return (
    <div className="navBar__container">
      <div className="navBar__marquee">
        <div className="navBar__track">
          <div>
            <b>Call Us</b> @ 7605025775 &bull; <b>Services We Offer</b> &gt;
            Counselling &bull; Courses &bull; Psycho Therapies
          </div>
        </div>
      </div>
      <div className="navBar">
        <div className="navBar__logo">
          <Link onClick={() => window.scrollTo(0, 0)} to="/">
            <img src={logo} alt="" />
          </Link>
          <div className="navBar__logoText">
            <h3>Psychological Ways</h3>
            <p> A unit of the Earthconscious Society</p>
          </div>
        </div>
        <div className="navBar__menuIcon" onClick={handleToggle}>
          {toggle ? <CgClose /> : <CgMenuRight />}
        </div>
        <div className={toggle ? "showNav navBar__nav" : "hideNav navBar__nav"}>
          <ul>
            <li>
              <HashLink onClick={handleToggle} to="/#home">
                Home
              </HashLink>
            </li>
            <li>
              <HashLink onClick={handleToggle} to="/#services">
                Services
              </HashLink>
            </li>
            <li onClick={scrollToTop}>
              <Link onClick={handleToggle} to="/courses">
                Courses
              </Link>
            </li>
            {/* <li>
            <a onClick={handleToggle} href="#here">
              Gallery
            </a>
          </li> */}
            <li onClick={scrollToTop}>
              <Link onClick={handleToggle} to="/about">
                About
              </Link>
            </li>
            <li onClick={scrollToTop}>
              <Link onClick={handleToggle} to="/contact">
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
