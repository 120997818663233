import { Link } from "react-router-dom";

import durationImg from "../Assets/icons/duration.svg";
import feesImg from "../Assets/icons/fees.svg";
import mediumImg from "../Assets/icons/medium.svg";
import eligibilityImg from "../Assets/icons/eligibility.svg";
import suitableImg from "../Assets/icons/suitable.svg";
import "./Styles/Course.css";

const Course = ({ title, duration, fees, eligibility, suitable, id }) => {
  return (
    <div id={id} className="course">
      <h1>{title}</h1>
      <div className="course__detailSec">
        <div className="course__details">
          <div className="course__title">
            <img src={durationImg} alt="" />
            <p>
              <b>Duration : </b>{" "}
            </p>
          </div>
          <p>{duration}</p>
        </div>
        <div className="course__details smallIcon">
          <div className="course__title">
            <img src={feesImg} alt="" />
            <p>
              <b>Fees : </b>{" "}
            </p>
          </div>
          <p>
            {fees} Rs. <span>(In Installment)</span>
          </p>
        </div>
        <div className="course__details largeIcon">
          <div className="course__title">
            <img src={mediumImg} alt="" />
            <p>
              <b>Medium : </b>{" "}
            </p>
          </div>
          <p>English & Bengali</p>
        </div>
        <div className="course__details largeIcon">
          <div className="course__title">
            <img src={eligibilityImg} alt="" />
            <p>
              <b>Eligibility : </b>{" "}
            </p>
          </div>
          <p>{eligibility}</p>
        </div>
        <div className="course__details">
          <div className="course__title">
            <img src={suitableImg} alt="" />
            <p>
              <b>Suitable : </b>{" "}
            </p>
          </div>
          <p>{suitable}</p>
        </div>
      </div>
      <Link
        onClick={() => window.scrollTo(0, 0)}
        className="noLink"
        to="/contact"
      >
        <p className="course__actionBtn">Get In Touch</p>
      </Link>
    </div>
  );
};

export default Course;
