import React from "react";
import ServiceCard from "./ServiceCard";
import social from "../Assets/social.png";
import stress from "../Assets/stress.png";
import parent from "../Assets/parent.png";
import relationship from "../Assets/relationship.png";
import marital from "../Assets/marital.png";
import educational from "../Assets/educational.png";
import "./Styles/Services.css";

const Services = () => {
  return (
    <div id="services" className="services">
      <h2 className="heading">How we can help</h2>
      <div className="services__cards">
        <ServiceCard image={social} title="Social & Community" />
        <ServiceCard image={stress} title="Stress & Emotional" />
        <ServiceCard image={parent} title="Parent - Child" />
        <ServiceCard image={relationship} title="Relationship" />
        <ServiceCard image={marital} title="Pre & Post - Marital" />
        <ServiceCard image={educational} title="Educational" />
      </div>
    </div>
  );
};

export default Services;
