import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import CourseCard from "./Components/CourseCard";
import Hero from "./Components/Hero";
import NavBar from "./Components/NavBar";
import Services from "./Components/Services";
import Therapies from "./Components/Therapies";

import CoursesPage from "./Components/CoursesPage";
import About from "./Components/About";
import Contact from "./Components/Contact";
import Footer from "./Components/Footer";

function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/">
            <NavBar />
            <Hero />
            <Services />
            <CourseCard />
            <Therapies />
            <Footer />
          </Route>

          <Route exact path="/courses">
            <NavBar />
            <CoursesPage />
            <Footer />
          </Route>

          <Route exact path="/about">
            <NavBar />
            <About />
            <Footer />
          </Route>

          <Route exact path="/contact">
            <NavBar />
            <Contact />
            <Footer />
          </Route>

          <Route>
            <h1 className="display-3 mt-5 text-center">404: Not Found</h1>
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;
