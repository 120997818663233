import React from "react";
// import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { ImBooks } from "react-icons/im";
import { HashLink } from "react-router-hash-link";
import courseImg from "../Assets/courses.png";
import "./Styles/CourseCard.css";

const CourseCard = () => {
  return (
    <div id="courses" className="courseCard">
      <h1 className="heading">Courses We Offer</h1>
      <div className="courseCard__container">
        <div className="courseCard__image">
          <img src={courseImg} alt="" />
        </div>
        <div className="courseCard__list">
          {/* <Link
            onClick={() => window.scrollTo(0, 0)}
            to="/courses"
            className="courseCard__btn"
          >
            Courses
            <span>
              <HiOutlineArrowNarrowRight />
            </span>
          </Link> */}

          <HashLink to="/courses#1">
            <div className="courseCard__course">
              <span>{<ImBooks />}</span>
              <h4>Applied counselling, psychology and body language</h4>
            </div>
          </HashLink>

          <HashLink to="/courses#2">
            <div className="courseCard__course">
              <span>{<ImBooks />}</span>
              <h4>Mental Health & Advanced Counselling</h4>
            </div>
          </HashLink>

          <HashLink to="/courses#3">
            <div className="courseCard__course">
              <span>{<ImBooks />}</span>
              <h4>Advanced Counselling, Project and Application</h4>
            </div>
          </HashLink>

          <HashLink to="/courses#4">
            <div className="courseCard__course">
              <span>{<ImBooks />}</span>
              <h4>Mental Health, Depression, Illness and Disorder</h4>
            </div>
          </HashLink>

          <HashLink to="/courses#5">
            <div className="courseCard__course">
              <span>{<ImBooks />}</span>
              <h4>Psychotherapics and Counselling</h4>
            </div>
          </HashLink>

          <HashLink to="/courses#6">
            <div className="courseCard__course">
              <span>{<ImBooks />}</span>
              <h4>Teenage and Marital Counselling</h4>
            </div>
          </HashLink>
        </div>
      </div>
    </div>
  );
};

export default CourseCard;
