import React from "react";
import "./Styles/About.css";

const About = () => {
  return (
    <div>
      <div className="about__aboutContainer">
        <div className="about__aboutTitle">
          <h1>About</h1>
        </div>
        <div className="about__aboutText">
          <h5>
            We provide multifaceted psychological therapies ranging from
            individual counseling, psychotherapy to parental counselling,
            marital counselling and many more. We also extend alternative
            therapies like music as therapy, yoga as therapy, play as therapy,
            art as therapy and psychotherapy which diversifies the experience
            one can undergo in 'Psychological Ways'. Psychological Ways runs by
            Earthconscious Society
          </h5>
        </div>
      </div>
      <div className="about__mission">
        <div className="heading about__missionHeading">
          <h1>Our Mission</h1>
        </div>
        <div className="about__missionContainer">
          <div className="about__missionTitle">
            <h1>1</h1>
          </div>
          <div className="about__missionText">
            <h1>
              We educate everybody willing to walk through the subject of
              Psychology.
              {/* We educate anyone who is willing to delve into the subject of
              psychology. */}
            </h1>
          </div>
        </div>
        <div className="about__missionContainer">
          <div className="about__missionTitle">
            <h1>2</h1>
          </div>
          <div className="about__missionText">
            <h1>
              We train students who want to pursue career in the area of
              Psychology.
              {/* We conduct trainings for those who are willing to pursue a career
              in the field of psychology. */}
            </h1>
          </div>
        </div>
        <div className="about__missionContainer">
          <div className="about__missionTitle">
            <h1>3</h1>
          </div>
          <div className="about__missionText">
            <h1>
              We guide persons who need counselling assistance in their daily
              life.
              {/* We are just a mail away in case you are looking for counselling
              assistance for your daily life. */}
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
