import React from "react";
// import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import hero2 from "../Assets/hero2.png";
import "./Styles/Hero.css";

function Hero() {
  const variant = {
    hidden: {
      opacity: 0,
      y: 20,
    },

    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1,
        staggerChildren: 0.5,
      },
    },
  };
  return (
    <motion.div
      variants={variant}
      initial="hidden"
      animate="visible"
      id="home"
      className="hero"
    >
      <motion.div variants={variant} className="hero__headings">
        <motion.h1 variants={variant}>Do you need a hand?</motion.h1>
        <motion.h3 variants={variant}>Relax, I held it.</motion.h3>
        <motion.h6 variants={variant}>
          <span>Psychological Ways</span> provides multifaceted psychological
          therapeutic services in many dimensions such as Individual Counselling
          to Psychotherapy, Parental Counselling, Marital Counselling and many
          more.
        </motion.h6>
        <a
          // onClick={() => window.scrollTo(0, 0)}
          // to="/contact"
          href="tel:+917605025775"
          className="hero__btn noLink"
        >
          Call Us : <b>7605025775</b>
        </a>
      </motion.div>
      <div className="hero__image">
        <motion.img variants={variant} src={hero2} alt="" />
      </div>
    </motion.div>
  );
}

export default Hero;
