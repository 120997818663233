import React from "react";
import Course from "./Course";
import "./Styles/CoursesPage.css";

const CoursesPage = () => {
  return (
    <div className="coursesPage">
      <h1 className="heading">Courses We Offer</h1>
      <h4>
        We cater to every need of yours! visit the courses and see for yourself.
      </h4>
      <div className="coursesPage__courses">
        <Course
          id="1"
          title="Applied counselling, psychology and body language"
          duration="7 Months"
          fees="6,800"
          eligibility="Graduation or Equivalent / Students pursuing graduation"
          suitable="Teachers, Public Relation Professionals, Parents, MBA Students,
           Home Makers, Any individual who wants to gain knowledge of
          psychology, NGO Persons, Counsellors."
        />
        <Course
          id="2"
          title="Mental Health & Advanced Counselling"
          duration="10 Months"
          fees="8,000"
          eligibility="Graduation or Equivalent / Students pursuing graduation"
          suitable="Teachers, Public Relation Professionals, Parents, MBA Students,
           Home Makers, Any individual who wants to gain knowledge of
          psychology, NGO Persons."
        />
        <Course
          id="3"
          title="Advanced Counselling, Project and Application"
          duration="6 Months"
          fees="6,000"
          eligibility="Graduation or Equivalent / Students pursuing graduation"
          suitable="Teachers, Public Relation Professionals, Parents, MBA Students,
           Home Makers, Any individual who wants to gain knowledge of
          psychology, NGO Persons."
        />
        <Course
          id="4"
          title="Mental Health, Depression, Illness and Disorder"
          duration="5 Months"
          fees="5,600"
          eligibility="Graduation or Equivalent / Students pursuing graduation"
          suitable="Teachers, Public Relation Professionals, Parents, MBA Students,
           Home Makers, Any individual who wants to gain knowledge of
          psychology, NGO Persons."
        />
        <Course
          id="5"
          title="Psychotherapics and Counselling"
          duration="6 Months"
          fees="6,000"
          eligibility="Graduation or Equivalent / Students pursuing graduation"
          suitable="Teachers, Public Relation Professionals, Parents, MBA Students,
           Home Makers, Any individual who wants to gain knowledge of
          psychology, NGO Persons."
        />
        <Course
          id="6"
          title="Teenage and Marital Counselling"
          duration="6 Months"
          fees="6,200"
          eligibility="Graduation or Equivalent / Students pursuing graduation"
          suitable="Students, University pass outs, young people pursuing jobs."
        />
      </div>
    </div>
  );
};

export default CoursesPage;
