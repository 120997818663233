import React from "react";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import "./Styles/Footer.css";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer__container">
        {/* Right Side */}
        <div className="footer__leftSide">
          <div>
            <h1>Psychological Ways</h1>
            <b>
              <p className="footer__description">
                A unit of the Earthconscious Society
              </p>
            </b>
          </div>

          <p className="footer__description">
            Psychological Ways provides multifaceted psychological therapeutic
            services in many dimensions such as Individual Counselling to
            Psychotherapy, Parental Counselling, Marital Counselling and many
            more.
          </p>
        </div>

        {/* Left Right */}
        <div className="footer__leftSide footer__rightSide">
          <h1>Reach Us On</h1>

          <p>
            <span>
              <AiOutlinePhone />
            </span>
            <a href="tel:+917605025775">+91-76050-25775</a> /{" "}
            <a href="tel:+919163009839">+91-91630-09839</a>
          </p>

          <p>
            <span>
              <AiOutlineMail />
            </span>
            <a href="mailto:psychologicalways@gmail.com">
              psychologicalways@gmail.com
            </a>
          </p>

          <div className="footer__social">
            <h1>Find Us On</h1>
            <div className="footer__iconContainer">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/Psychology-and-counseling-with-Yoga-As-Therapy-105131860915469/?hc_ref=ARRsN3nrmGVqFekOcRINynCz6fTia5hDmP5Ana6JcD3ZfNNbgxScEYdYVQmESHRqbWg&fref=nf&__tn__=kC-R"
                className="footer__icon"
              >
                <FaFacebookF />
              </a>
              <a href="#instagram" className="footer__icon">
                <FaInstagram />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="footer__copyRight">
        <p>
          Copyright &#169; 2021 Psychological Ways | Developed By{" "}
          <a target="_blank" rel="noreferrer" href="http://hey-azhar.web.app/">
            heyAzhar.dev
          </a>
        </p>
      </div>
    </div>
  );
};

export default Footer;
