import React from "react";
import { GrYoga } from "react-icons/gr";
import { RiPsychotherapyFill } from "react-icons/ri";
import {
  GiBabyfootPlayers,
  GiMusicalScore,
  GiVineFlower,
} from "react-icons/gi";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { Link } from "react-router-dom";

import therapiesImg from "../Assets/therapies.png";
import "./Styles/Therapies.css";

const Therapies = () => {
  return (
    <div id="therapies" className="therapies">
      <h1 className="heading">Out of the box Therapies</h1>
      <div className="therapies__container">
        <div className="therapies__list">
          <Link
            onClick={() => window.scrollTo(0, 0)}
            to="/contact"
            className="therapies__therapy noLink"
          >
            <span>{<GrYoga />}</span>
            <h4 className="noLink therapies__title">Yoga as therapy</h4>
            <div className="therapies__btn">
              <h4>I want to feel better</h4>
              <span>
                <HiOutlineArrowNarrowRight />
              </span>
            </div>
          </Link>
          <Link
            onClick={() => window.scrollTo(0, 0)}
            to="/contact"
            className="therapies__therapy noLink"
          >
            <span>{<RiPsychotherapyFill />}</span>
            <h4 className="noLink therapies__title">Psychotherapy</h4>
            <div className="therapies__btn">
              <h4>I want to feel better</h4>
              <span>
                <HiOutlineArrowNarrowRight />
              </span>
            </div>
          </Link>
          <Link
            onClick={() => window.scrollTo(0, 0)}
            to="/contact"
            className="therapies__therapy noLink"
          >
            <span>{<GiBabyfootPlayers />}</span>
            <h4 className="noLink therapies__title">Play as therapy</h4>
            <div className="therapies__btn">
              <h4>I want to feel better</h4>
              <span>
                <HiOutlineArrowNarrowRight />
              </span>
            </div>
          </Link>
          <Link
            onClick={() => window.scrollTo(0, 0)}
            to="/contact"
            className="therapies__therapy noLink"
          >
            <span>{<GiVineFlower />}</span>
            <h4 className="noLink therapies__title">Art as therapy</h4>
            <div className="therapies__btn">
              <h4>I want to feel better</h4>
              <span>
                <HiOutlineArrowNarrowRight />
              </span>
            </div>
          </Link>
          <Link
            onClick={() => window.scrollTo(0, 0)}
            to="/contact"
            className="therapies__therapy noLink"
          >
            <span>{<GiMusicalScore />}</span>
            <h4 className="noLink therapies__title">Music as therapy</h4>
            <div className="therapies__btn">
              <h4>I want to feel better</h4>
              <span>
                <HiOutlineArrowNarrowRight />
              </span>
            </div>
          </Link>
        </div>
        <div className="therapies__image">
          <img src={therapiesImg} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Therapies;
