import React from "react";
import { Link } from "react-router-dom";
import "./Styles/ServiceCard..css";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";

const ServiceCard = ({ image, title }) => {
  return (
    <div className="serviceCard">
      <div className="serviceCard__image">
        <img src={image} alt="" />
      </div>
      <div className="serviceCard__tittle">
        <h5>{title} Counselling</h5>
      </div>
      <Link
        onClick={() => window.scrollTo(0, 0)}
        to="/contact"
        className="serviceCard__btn noLink"
      >
        <h4>I want to feel better</h4>
        <span>
          <HiOutlineArrowNarrowRight />
        </span>
      </Link>
    </div>
  );
};

export default ServiceCard;
